<template>
    <div class="right">
        <div>
          <breadCrumb :siderContent="siderName" />
        </div>
        <div class="c_box">
          <div class="leftone">
            <div>
              <a-table
                :columns="columns"
                :data-source="demageArr"
                :rowKey="
                  (record, index) => {
                    return index;
                  }
                "
                 :pagination="{
                  pageSize:6,
                }"
                :locale="{emptyText: '暂无数据'}"
              >
                <span slot="icon" slot-scope="text, item"  @click="clickDe(item)">
                  <div class="delet">
                    <a-icon style="color: #ff3b30" type="close" />
                  </div>
                </span>
                <p slot-scope="record" style="margin: 0">
                  {{ record }}
                </p>
              </a-table>
            </div>
            <div class="myButton">
              <a-button class="OkBtn button" @click="changeshowbill">下一步</a-button>
              <a-button class="clearBtn button" @click="clickEmpty">清空</a-button>
            </div>
          </div>
          <div class="rightone stork_right">
             <div class="myInput">
                <a-input class="input" placeholder="请输入搜索内容" v-model="goods_no"  @change="onSearch"/> 
             </div>
            <a-tabs default-active-key="1" type="card" @change="callback">
                <a-tab-pane tab="全部商品"  key="0">
                <ul class="stock_goods">
                  <li
                    v-for="(item, index) in goodsListArr"
                    :key="index"
                    @click="clickEveryGood(item)"
                  >
                    <div class="goods_img">
                      <img :src="item.preview_url" alt=""  v-if="item.preview_url"/>
                      <img src="@/assets/defaultImg.png" alt=""  v-if="!item.preview_url"/>
                    </div>
                    <div class="goods_name">
                      {{ item.goods_name }}
                    </div>
                    <div class="stock_num">
                       <span> {{ item.goods_price }}<span v-if="item.goods_unit">/{{item.goods_unit}}</span></span>
                      <span>库存{{ item.stock_total }}<span v-if="item.is_weighing==2">(千克)</span></span>
                    </div>
                  </li>
                </ul>
              </a-tab-pane> 
               <a-tab-pane  v-for="(item) in tabList" :key="item.category_id" :tab="item.name" >
                <ul class="stock_goods">
                  <li
                    v-for="(item, index) in goodsListArr"
                    :key="index"
                    @click="clickEveryGood(item)"
                  >
                    <div class="goods_img">
                      <img :src="item.preview_url" alt="" v-if="item.preview_url"/>
                      <img src="@/assets/defaultImg.png" alt=""  v-if="!item.preview_url"/>
                    </div>
                    <div class="goods_name">
                      {{ item.goods_name }}
                    </div>
                    <div class="stock_num">
                       <span> {{ item.goods_price }}<span v-if="item.goods_unit">/{{item.goods_unit}}</span></span>
                      <span>库存{{ item.stock_total }}<span v-if="item.is_weighing==2">(千克)</span></span>
                    </div>
                  </li>
                </ul>    
              </a-tab-pane>             
            </a-tabs>      
            <div>             
            </div> 
            <div class="page">
               <a-pagination v-model="current" :total="total"  @change="changePage" :defaultPageSize="16" v-if="total>0"/>   
            </div>     
          </div>
        </div>
        <numchangeModal :isShow="shownumchange" @number="getNum" @modal="changeModal" :itemData="everyGoods"  :name="goods_name"/>
        <billModal :areShow="showbill" @choose="getReason(arguments)" @changeStatue="closeBill"/>
      </div>
</template>
<script>
import breadCrumb from "@/components/BreadCrumb/BreadCrumb";
import numchangeModal from "./numchangeModal";
import billModal from "./billModal";
import {stockloss} from '@/request/stock.js';
import {categoryList,goodsList} from '@/request/business.js';
import Cookies from "js-cookie";
const columns = [
  { title: "商品", dataIndex: "goods_name", key: "name" },
  { title: "库存", dataIndex: "stock_total", key: "age" },
  { title: "报损数", dataIndex: "loss_num", key: "address" },
  { title: "", scopedSlots: { customRender: "icon" } },
];
export default {
  props: [],
  components: {
  breadCrumb,
  numchangeModal,
  billModal,
  },
  data() {
    return {
        siderNumber: 0,
        columns,
        siderName: "商品报损", //sider切换内容
        shownumchange: false, //报损修改数量modal
        showbill: false,//确认提交报损单modal
        goodsListArr: [], //商品列表
        demageArr:[],//报损列表
        everyGoods:'',//点击每条商品
        tabList:[],//商品分类
        goods_no:"",//商品搜索
        total:0,
        current:1,//当前页
        category_id:'',//商品分类
        goods_name:'',//商品modal名称
    };
  },
  mounted(){
    this.goodsRequest();
    this.tabRequest();
  },
  methods: {
    callback(key){ //点击tabs切换
    this.current=1;
    this.category_id=key;
      this.goodsRequest()
    },
    changeshowbill() {
      if(this.demageArr.length>0){
        this.showbill = true;
      }else{
        this.$message.warn('请添加报损商品',1.5);
      }
    },
    changenumchange() { //修改数量
      this.shownumchange = true;
    },
    stocklossRequest(message){
      const _this=this;
        stockloss(message)
        .then((res) => {
          if(res.data.status===200){
              _this.$message.success('报损成功',1.5);
              const batch=res.data.data.batch && res.data.data.batch;
              if(message.is_print===1){
                _this.socketApi.websocketsend({"key": "baosunAction",value:{token: Cookies.get("Access-Token"),batch:batch} }); 
              }
              _this.goodsRequest()
              _this.showbill = false;
              _this.demageArr=[];
            }else{
               _this.$message.warn('报损失败',1.5);
            }              
          })
        .catch(result => {
            return false;
      })
    },
    goodsRequest(){    //商品列表请求
      const message={
        page:this.current,
        category_id:  parseInt(this.category_id) ? parseInt(this.category_id): "",
        page_size:16,
        goods_name:this.goods_no,
      }   
      goodsList(message)
            .then((res) => {
              if(res.data.status===200){
                const list =res.data.data.list && res.data.data.list;
                this.goodsListArr=list.data && list.data;
                this.total=list.total &&  list.total;        
              }           
            })
            .catch(result => {
              return false;
            })
    },
    clickEveryGood(item){
      this.shownumchange=true;
      this.everyGoods=item;
      this.goods_name=item.goods_name && item.goods_name;
    },
    getNum(object){ //获取报损数量
    if( this.demageArr.length>0){
      const index =this.findElem(this.demageArr,'goods_id',object.goods_id);
      if(index !==-1){
          this.demageArr[index].loss_num=object.loss_num;
      }else{
        this.demageArr.push(object);
      }  
    }else if(this.demageArr.length===0){
       this.demageArr.push(object);
    }   
    },
    getReason(number){//获取报损原因
      const message={
        is_print:number[1],
        remark:number[0],
        stockloss:this.demageArr
      }
      this.stocklossRequest(message);
    },
    changeModal(){ //关闭报损数量modal
      this.shownumchange=false;
    },
    closeBill(){ //关闭商品报损原因modal
      this.showbill = false;
    },
    tabRequest(){  //商品分类请求
      categoryList()
            .then((res) => {
              if(res.data.status===200){
                const list =res.data.data.list && res.data.data.list;
                this.tabList=list;
              }           
            })
            .catch(result => {
              return false;
            })
    },
    onSearch(){ //商品列表搜索
      this.goodsRequest()
    },
    clickEmpty(){ //清空按钮
      this.demageArr=[];
    },
    changePage(current){ //点击分页
      this.current = current;
      this.goodsRequest()
    },
    findElem(array, attr, val) {//筛选数组是否存在某个值
      for (var i = 0; i < array.length; i++) {
          if (array[i][attr] == val) {
              return i; //返回当前索引值
          }
      }
      return -1;
    },
    clickDe(item){
     const index =this.findElem(this.demageArr,'goods_id',item.goods_id);
     if(index !== -1){
       this.demageArr.splice(index);
     }
     
    }
  }, 
};
</script>
<style lang="less" scoped >
  .right {
    height: 100%;
    width: 100%;
  }
  .c_box{
    height: calc(100% - 5.5vh);
    width: 100%;
    display: flex;
  }
  .leftone {
    position: relative;
    .ant-table-wrapper {
      width: 26vw;
    }
    .myButton{
      position: absolute;
      bottom: 2vh;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      flex-direction: column;
      .button {
        width: 18vw;
        height: 4.8vh;
        border-radius: 0.5vh;
        font-size: 1.8vh;
        margin-top: 1vh;
      }
    }
    /deep/.ant-table {
      font-size: 1.8vh;
    }
    /deep/.ant-table-thead {
      font-size: 1.8vh;
    }
  }
  .rightone {
    padding: 1vh;
    flex: 1;
    background-color: #edeef2;
    position: relative;
    .myInput{
      position: absolute;
      right: 1.5vw;
      top: 1vh;
      z-index: 10;
      .input {
        width: 20vh;
        height: 4.1vh;
      }
    }
    /deep/.ant-tabs{
      width: 100%;
    }
    
  }
  .ant-pagination{
    text-align: right;
    padding-right: 0.5vw;
  }
  /deep/.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    margin: 0 1vh !important;
  }
  /deep/.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
    height: 46px;
  }
  
  /deep/.ant-pagination-prev {
    min-width: 3vh;
    height: 3.6vh;
    line-height: 3.6vh;
    margin-right: 0.5vh;
  }
  /deep/.ant-pagination-next {
    min-width: 3vh;
    height: 3.6vh;
    line-height: 3.6vh;
  }
  /deep/.ant-pagination-item {
    min-width: 3vh;
    height: 3.6vh;
    line-height: 3.6vh;
    margin-right: 0.5vh;
  }
  /deep/.ant-table-pagination.ant-pagination {
    margin: 1vh 3vh;
  }
  /deep/.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    border-radius: 0.5vh 0.5vh 0.5vh 0.5vh;
    font-size: 1.8vh;
    text-align: center;
    line-height: 4vh;
    height: 4.1vh;
    color: black;
  }

  .delet{
    cursor: pointer;
  }
  
  /deep/.ant-table-content{
    height:70vh;
  }
  /deep/.ant-tabs-bar{
    width:38vw;
    margin-bottom: 1vh;
  }
  /deep/.ant-tabs-nav-container{
    line-height: 46px;
  }
</style>